@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
    --sal: env(safe-area-inset-left);
}
:root {
    --ion-color-subtle: #f0a492;
    --ion-color-subtle-rgb: 240,164,146;
    --ion-color-subtle-contrast: #ffffff;
    --ion-color-subtle-contrast-rgb: 255,255,255;
    --ion-color-subtle-shade: #d39080;
    --ion-color-subtle-tint: #f2ad9d;
}

.ion-color-subtle {
    --ion-color-base: var(--ion-color-subtle);
    --ion-color-base-rgb: var(--ion-color-subtle-rgb);
    --ion-color-contrast: var(--ion-color-subtle-contrast);
    --ion-color-contrast-rgb: var(--ion-color-subtle-contrast-rgb);
    --ion-color-shade: var(--ion-color-subtle-shade);
    --ion-color-tint: var(--ion-color-subtle-tint);
}

li{
    list-style-type: disc;
}