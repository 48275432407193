.catalina-scrollpage-header {
  --border-style: none;
  --padding-top: 10px;
  --color: rgba(0, 0, 0, 0.7);
}
.pointer {
  height: 15px;
  width: 15px;
  background: #FFF4D8;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 3px 0;
  margin-bottom: -8px;
  position: relative;
  left: calc(0.5vw);
}
