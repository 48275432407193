.speech {
  /* (A1) FONT */
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  /* (A2) COLORS */
  color: #fff;
  background: #FF592A;

  /* (A3) DIMENSIONS + POSITION */
  position: absolute;
  top: 0px;
  padding: 5px;
  border-radius: 10px;
  margin: 1px 1px;
  max-width: 70px;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 5px solid transparent;

  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

/* (B2) BOTTOM "CALLOUT TAIL" */
.bottom.speech::after {
  /* (B2-1) DOWN TRIANGLE */
  border-top-color: #FF592A;
  border-bottom: 0;

  /* (B2-2) POSITION AT BOTTOM */
  bottom: -5px;
  margin-left: -25px;
}