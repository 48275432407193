@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.placeholder-b7b7b7 {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b7b7b7;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b7b7b7;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b7b7b7;
  }
}

.rounded-25-rem {
  --border-radius: 0.25rem;
}

.disabled-DFDFDF.button-disabled {
  // --ion-color-contrast: #DFDFDF !important;
  --ion-color-base: #DFDFDF !important;
}

.border-D9D9D9 {
  --border-color: #D9D9D9;
  --color: var(--ion-color-danger);
}

.checkbox-square {
  --border-radius: 4px;
}
#footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  justify-content : center;
}