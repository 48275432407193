.segment-scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  
  &::-webkit-scrollbar {
    display: none;
  }
}
.confirm-modal{
  .alert-title.sc-ion-alert-ios{
    font-weight: 500;
  }
  .alert-button-role-cancel{
    color: gray;
  }
  .alert-message{
    padding-top: 10px;
  }
}