ion-item.settings-item {
    --inner-padding-top: 7px;
    --inner-padding-bottom: 7px;
}

ion-label.settings-label {
    font-size: 15px !important;
}

ion-button.settings-button {
    font-size: 15px;
    margin-top: 20px;
    padding-left: 3px;
}